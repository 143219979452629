import React, { useState } from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import "./Topnavbar.module.css";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoIosKeypad } from "react-icons/io";
import { withNamespaces } from "react-i18next";
import GetStore from "../../getStore.js";
import Cookies from "universal-cookie";

function Topnavbar(props) {
  const storeData = GetStore();
  const cookies = new Cookies();
  const navDropdownTitleLang = {
    en: (
      <span
        style={{
          display: "flex",
          flexDirection: "inline-row",
          alignItems: "center"
        }}
      >
        <img
          alt=""
          src="/assets/uk.svg"
          height="22"
          className="d-inline-block align-top"
        />{" "}
        &nbsp; &nbsp; <span className={"d-none d-md-block"}>English</span>
      </span>
    ),
    fr: (
      <span
        style={{
          display: "flex",
          flexDirection: "inline-row",
          alignItems: "center"
        }}
      >
        <img
          alt=""
          src="/assets/fr.svg"
          height="22"
          className="d-inline-block align-top"
        />{" "}
        &nbsp; &nbsp; <span className={"d-none d-md-block"}>Français</span>
      </span>
    )
  };

  const definedLan = localStorage.getItem("rightTime_lang")
    ? localStorage.getItem("rightTime_lang")
    : "en";
  const [navDropdownTitle, setLang] = useState(
    navDropdownTitleLang[definedLan]
  );

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setLang(navDropdownTitleLang[lng]);
    localStorage.setItem("rightTime_lang", lng);
  };

  const toggleSidenav = () => {
    var element = document.getElementsByClassName("dashShell")[0];

    if (element.classList) {
      element.classList.toggle("toggled");
    } else {
      // For IE9
      var classes = element.className.split(" ");
      var i = classes.indexOf("toggled");

      if (i >= 0) classes.splice(i, 1);
      else classes.push("toggled");
      element.className = classes.join(" ");
    }
  };
  const alias = window.location.hostname.split(".");

  return (
    <Navbar
      bg="white"
      className={"navbar-toggleable-xl mainNav"}
      expand="lg"
      fixed={props.fixed}
      style={{ minHeight: 64, borderBottom: "solid .75px #F0F0F0" }}
    >
      {props.dashMenu ? (
        <button
          className={"btn btn-default pl-0"}
          onClick={() => {
            toggleSidenav();
          }}
        >
          <img src="/assets/sidebar/menu.svg" alt="" height={22} />
        </button>
      ) : (
        ""
      )}

      <Navbar.Brand href={props.brandHref ? props.brandHref : "/"}>
        <img
          alt=""
          src="/assets/landing/General/SVG/RT-logo.svg"
          width="124"
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Nav
        className="ml-auto"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        {!cookies.get("rightTimeAlias") && !props.scpage ? (
          <Nav.Link href="https://account.rightcom.co">
            {props.t("sign_in")}
          </Nav.Link>
        ) : (
          ""
        )}
        {cookies.get("rightTimeAlias") ? (
          <Nav.Link
            href={
              "https://" +
              cookies.get("rightTimeAlias") +
              ".righttime.rightcom.co"
            }
          >
            {props.t("sign_in")}
          </Nav.Link>
        ) : (
          ""
        )}

        <NavDropdown
          title={navDropdownTitle}
          id="basic-nav-dropdown"
          style={{ right: 0, after: "" }}
        >
          <NavDropdown.Item onClick={() => changeLanguage("en")}>
            {navDropdownTitleLang.en}
          </NavDropdown.Item>
          <NavDropdown.Item onClick={() => changeLanguage("fr")}>
            {navDropdownTitleLang.fr}
          </NavDropdown.Item>
        </NavDropdown>
        {!props.scpage ? (
          <Nav.Link href="#" style={{ padding: 5 }}>
            <IoIosKeypad style={{ fontSize: 26 }} />
          </Nav.Link>
        ) : (
          ""
        )}
      </Nav>
    </Navbar>
  );
}

Topnavbar.propTypes = {
  fixed: PropTypes.string,
  brandHref: PropTypes.string,
  dashMenu: PropTypes.bool,
  scpage: PropTypes.bool
};

Topnavbar.defaultProps = {
  fixed: "",
  brandHref: "/",
  dashMenu: false,
  scpage: false
};

export default withNamespaces()(Topnavbar);
